import React, { useState } from "react";
import Logo from "../../assets/images/Ecentric-logo.svg";
import ToggleIcon from "../../assets/images/menu-icon.svg";
import { Link } from "react-router-dom";

const Header = ({ eventName }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleToggleClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <header>
      <div className="header-inner custom-container" style={{ position: 'relative' }}>
        <Link className="navbar-brand" to={"https://www.ecentric.co.za/"}>
          <img src={Logo} alt="logo" />
        </Link>
        <p style={{right: '0', position: 'absolute', marginRight: '16px' }} >
          {eventName} <span>Dashboard</span>
        </p>
      </div>
    </header>
  );
};

export default Header;
