export const formatNumber = (number) => {
  return (
    number && number.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, " ")
  );
};

export const EVENT_TYPES = {
  BLACK_FRIDAY: "BLACK_FRIDAY",
  CYBER_MONDAY: "CYBER_MONDAY",
};

export const EVENTS = {
  BLACK_FRIDAY: "Black Friday Week",
  CYBER_MONDAY: "Cyber Monday",
};

export const CONTENT = {
  BLACK_FRIDAY: {
    counterTitle: "Get Ready For The Black Friday Frenzy!",
    counterDescription: "The epic countdown has begun! Get ready for the retail event of the year! The Ecentric Black Friday Dashboard is gearing up to launch, and this time, it’s live for the entire week, delivering a world of real-time payment insights right at your fingertips.",
    dashboardTitle: "Welcome to the Ecentric Black Friday Week Dashboard",
    dashboardDescription: "We're thrilled to have you join us for another exciting day of shopping insights. Get ready to dive into the latest transaction statistics for today and compare them to last year's records. Our dynamic dashboard offers real-time updates, providing you with a front-row seat to the pulse of in-store and online purchases."
  },
  CYBER_MONDAY: {
    counterTitle: "",
    counterDescription: "Wow, Black Friday week was a whirlwind of excitement! But don't let the shopping spree end just yet. Get ready for the next round of excitement as we gear up for Cyber Monday. The countdown is on and the thrill continues. Stay tuned!",
    dashboardTitle: "Welcome to the Ecentric Cyber Monday Dashboard",
    dashboardDescription: "We're thrilled to have you join us for another exciting day of retail insights. Get ready to dive into the latest transaction insights for Cyber Monday and compare them to last year's records. Our dynamic dashboard offers real-time updates, providing you with a front-row seat to the pulse of in-store and online purchases."
  }
};
